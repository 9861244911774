/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */

import { StopGo } from 'utilities/stopgo.js';
import { doTimeout } from 'utilities/timeout-utils.js';
import { proto, objectToQueryParams, parseUrl } from 'utilities/url.js';
import { seqId } from 'utilities/seqid.js';
import { merge, clone, eachLeaf, isBasicType } from 'utilities/obj.js';
import {
  elemAddClass,
  elemAppend,
  elemBind,
  elemContainsOffset,
  elemFromObject,
  elemHasClass,
  elemHeight,
  elemIsInside,
  elemRebind,
  elemRemove,
  elemRemoveClass,
  elemStyle,
  elemUnbind,
  elemUnbindAllInside,
  elemWidth,
} from 'utilities/elem.js';
import { dynamicImport } from 'utilities/dynamicImport.ts';
import { mediaResponseTransformer } from './_medias_controller_api_response_transformer.js';
import { UppyUploadAdapter } from './_uppy_adapter.js';

window._wq.push((Wistia) => {
  if (Wistia.Uploader) {
    return;
  }

  Wistia.Uploader = class Uploader {
    constructor(options = {}) {
      this._addToActivePool = this._addToActivePool.bind(this);
      this._removeFromActivePool = this._removeFromActivePool.bind(this);
      this._inActivePool = this._inActivePool.bind(this);
      this._resetUploader = this._resetUploader.bind(this);
      this._handleIframeMessage = this._handleIframeMessage.bind(this);
      this._buildAdditionalParams = this._buildAdditionalParams.bind(this);
      this._onFilesSubmitted = this._onFilesSubmitted.bind(this);
      this._onFileAdded = this._onFileAdded.bind(this);
      this._maybeReleaseUploadingLock = this._maybeReleaseUploadingLock.bind(this);
      this._onFileComplete = this._onFileComplete.bind(this);
      this._onBeforeUnload = this._onBeforeUnload.bind(this);
      this._onClickedCancel = this._onClickedCancel.bind(this);
      this._onUploadButtonStateChange = this._onUploadButtonStateChange.bind(this);
      this.options = options;
      if (this.options.dropIn) {
        const existingUploader = this._elemFromInput(this.options.dropIn)?._wistiaUploader;
        existingUploader?.destroy();
      }
      this.options = merge(
        {
          dropZoneClickable: true,
          useUploadV2: true,
        },
        this.options,
      );
      this.setProjectId(this.options.projectId);
      this.uuid = seqId();
      this._processedFile = new StopGo();
      this.dataApi = new Wistia.DataApi({ accessToken: this.options.accessToken });
      dynamicImport('assets/external/interFontFace.js');
      this.setup();
    }

    _addToActivePool() {
      if (!this._inActivePool()) {
        return Wistia.Uploader.activePool.push(this);
      }
    }

    _removeFromActivePool() {
      return (Wistia.Uploader.activePool = Wistia.Uploader.activePool.filter((u) => u !== this));
    }

    _inActivePool() {
      for (let uploader of Wistia.Uploader.activePool) {
        if (uploader === this) {
          return true;
        }
      }
      return false;
    }

    setFileName(fileName) {
      this._fileName = fileName;
      if (this.iframeElem) {
        return this._sendIframeMessage({ event: 'setFileName', value: fileName });
      }
    }

    setFileDescription(fileDescription) {
      this._fileDescription = fileDescription;
      if (this.iframeElem) {
        return this._sendIframeMessage({ event: 'setFileDescription', value: fileDescription });
      }
    }

    setProjectId(projectId) {
      this._projectId = projectId;
      if (this.iframeElem) {
        return this._sendIframeMessage({ event: 'setProjectId', value: projectId });
      }
    }

    setEmbedCodeOptions(embedCodeOptions) {
      return (this._embedCodeOptions = embedCodeOptions);
    }

    setAccessToken(accessToken) {
      this._accessToken = accessToken;
      return (this.dataApi.accessToken = accessToken);
    }

    getEmbedCode(media, embedOptions, callback) {
      const hashedId = typeof media === 'string' ? media : media.id;
      embedOptions = merge({ embedType: 'async' }, embedOptions);
      return this.dataApi.media.embedCode(hashedId, embedOptions, callback);
    }

    getThumbnailUrl(hashedId, options, callback) {
      if (!options.tries) {
        options.tries = 0;
      }
      if (options.tries > 10) {
        // Either the request to get the mediaData failed a bunch, or it's taking a very long time for the bakery to encode the video's thumbnail URL. Either way, we give up.
        this.error(`failed to get thumbnail URL for media with id ${hashedId}`);
        return;
      }

      if (hashedId.id) {
        hashedId = hashedId.id;
      }

      const retryInFiveSeconds = () => {
        return setTimeout(() => {
          options = merge({}, options, { tries: options.tries + 1 });
          return this.getThumbnailUrl(hashedId, options, callback);
        }, 5000);
      };

      return fetch(`//${Wistia.remote.embedHost()}/embed/medias/${hashedId}.json`)
        .then((resp) => resp.json())
        .then((resp) => {
          const mediaData = resp.media;
          const stillAsset = Wistia.Player.stillAsset(mediaData);
          const original = Wistia.Player.asset(mediaData, { type: 'original' });
          if (stillAsset?.status === Wistia.Player.READY) {
            let height;
            let width;
            const aspect = original.width / original.height;
            const url = parseUrl(stillAsset.url.replace('.bin', '.jpg'));

            if (options.params) {
              url.params = options.params;
            }

            if (options.width && options.height) {
              ({ width } = options);
              ({ height } = options);
            } else if (options.width) {
              ({ width } = options);
              height = width / aspect;
            } else if (options.height) {
              ({ height } = options);
              width = height * aspect;
            } else {
              width = 640;
              height = width / aspect;
            }

            width = Math.round(width);
            height = Math.round(height);

            if (!options.mode || options.mode === 'crop-resize') {
              url.params.image_crop_resized = `${width}x${height}`;
            } else if (options.mode === 'resize') {
              url.params.image_resize = `${width}x${height}`;
            }

            return callback(url.absolute());
          }
          return retryInFiveSeconds();
        })
        .catch(() => {
          retryInFiveSeconds();
        });
    }

    fileExtensions() {
      if (this.options.fileExtensions) {
        return `${this.options.fileExtensions}`.split(/\s+/);
      }
      return [];
    }

    videoExtensions() {
      if (this.options.videoExtensions) {
        return `${this.options.videoExtensions} ${this.options.extraVideoExtensions}`.split(/\s+/);
      }
      return (
        this._videoExtensions ||
        (this._videoExtensions = `\
webm mkv flv vob ogv ogg avi mov qt wmv asf mp4 m4p m4v mpg mpeg m2v
svi 3gp 3g2 flv f4v f4p f4a f4b ${this.options.extraVideoExtensions}\
`.split(/\s+/))
      );
    }

    removePreview() {
      return this._removePreview();
    }

    cancel(options = {}) {
      if (this.upload_client) {
        if (this.upload_client.files && this.upload_client.files.length > 0) {
          for (let file of this.upload_client.files) {
            file._cancelled = true;
          }
          this.upload_client.cancel();
          if (options.flash !== false) {
            this.uploadButton?.flashCancelled();
          }
          this.trigger('uploadcancelled', this._currentFile);
        } else {
          Wistia.error("Unable to cancel: Couldn't find file to cancel.");
        }
      } else if (this.iframeElem) {
        if (options.flash !== false) {
          this.uploadButton?.flashCancelled();
        }
        this.trigger('uploadcancelled', this._currentFile);
      } else {
        Wistia.error("Unable to cancel: Couldn't find upload client or upload iframe.");
      }

      // Mark as not uploading if nothing else is queued after cancel
      this._uploadingEachFile = false;
      this._removeFromActivePool();
      return Wistia.Uploader.trigger('uploadingchange');
    }

    isUploading() {
      return !!this._uploading;
    }

    setup() {
      if (this.options.dropIn) {
        this._countMetric('dropin-option');
        this._setupDropinElems();
      } else {
        this._countMetric('custom-option');
        this.buttonElem = this._elemFromInput(this.options.button);
        this.dropZoneElem = this._elemFromInput(this.options.dropZone);
        this.previewElem = this._elemFromInput(this.options.preview);
      }

      if (this.buttonElem) {
        if (this.options.customButton) {
          this.uploadButton = new Wistia.DataApi.UploadButton(this.buttonElem);
        } else {
          this.uploadButton = new Wistia.DataApi.DefaultUploadButton(this.buttonElem, {
            defaultText: this.options.defaultButtonText,
            classPrefix: this.options.buttonClassPrefix,
          });
        }
        this.uploadButton.bind('confirmed-cancel', this._onClickedCancel);
        this.uploadButton.bind('statechange', this._onUploadButtonStateChange);
      }

      if (this._noUploadSupport()) {
        this._countMetric('init-no-upload-support');
        this._initNoUploadSupport();
      } else if (this._useIframeUploader()) {
        this._countMetric('init-iframe-uploader');
        this._initIframeUploader();
      } else {
        this._countMetric('init-embeddable-uploader');
        this._initUploadClient();
      }

      return this._setupBindings();
    }

    destroy() {
      this.trigger('beforedestroy');

      // Don't trigger anymore events as part of the destroy process.
      this._bindings = {};
      if (this.buttonElem) {
        elemUnbindAllInside(this.buttonElem);
      }
      if (this.dropZoneElem) {
        elemUnbindAllInside(this.dropZoneElem);
      }
      if (this.previewElem) {
        elemUnbindAllInside(this.previewElem);
      }
      this._maybeReleaseUploadingLock();
      if (this.iframeElem) {
        this._destroyIframeUploader();
      }
      this.upload_client?.destroy?.();

      // If this is a drop-in uploader, we have injected all the elements, and
      // can safely remove them on destroy. We can't do this for non-drop-in
      // uploaders because they are user-supplied elements that might be used
      // elsewhere.
      if (this.options.dropIn) {
        const dropInEl = this._elemFromInput(this.options.dropIn);
        if (dropInEl) {
          dropInEl.innerHTML = '';
          return (dropInEl._wistiaUploader = undefined);
        }
      }
    }

    _elemFromInput(input) {
      if (typeof input === 'string') {
        return document.getElementById(input);
      }
      return input;
    }

    _setupDropinElems() {
      this.dropZoneElem = this._elemFromInput(this.options.dropIn);
      this.dropZoneElem._wistiaUploader = this;
      elemAddClass(this.dropZoneElem, 'wistia_upload_drop_zone');

      if (this.options.dropIn) {
        this.dropZoneElem.innerHTML = '';
      }

      if (this.options.theme === 'dark_background') {
        elemAddClass(this.dropZoneElem, 'wistia_upload_theme_dark_background');
      } else {
        elemAddClass(this.dropZoneElem, 'wistia_upload_theme_light_background');
      }

      this.previewElem = elemFromObject({
        id: seqId('wistia_', '_upload_preview'),
        class: 'wistia_upload_preview',
      });
      const uploadGraphic = elemFromObject({
        tagName: 'img',
        src: this.uploadIconSrc(),
        class: 'wistia_upload_graphic',
        alt: 'Upload Video',
      });
      const uploadText = elemFromObject({
        class: 'wistia_upload_text',
        innerHTML: 'Drag and drop a file or',
        style: {
          visibility: 'hidden',
        },
      });
      this.buttonElem = elemFromObject({
        id: seqId('wistia_', '_upload_button'),
        alt: 'Upload Video',
      });
      const dropZoneHover = elemFromObject({
        class: 'wistia_upload_drop_zone_hover',
      });

      elemAppend(this.dropZoneElem, this.previewElem);
      elemAppend(this.dropZoneElem, uploadGraphic);
      elemAppend(this.dropZoneElem, uploadText);
      elemAppend(this.dropZoneElem, dropZoneHover);
      return elemAppend(this.dropZoneElem, this.buttonElem);
    }

    uploadIconSrc() {
      if (this.options.theme === 'dark_background') {
        return '//fast.wistia.com/images/upload-icon-white@2x.png';
      }
      return '//fast.wistia.com/images/upload-icon@2x.png';
    }

    _setupBindings() {
      this.bind('uploadstart', () => {
        this._uploading = true;
        if (!window.onbeforeunload) {
          window.onbeforeunload = this._onBeforeUnload;
        }
        return this._countMetric('uploadstart');
      });

      this.bind('uploadprogress', (file, ratio) => {
        if (this._uploading) {
          return this.uploadButton?.updateUploadingPercent(ratio);
        }
        return this.uploadButton?.showUploading(ratio);
      });

      this.bind('uploadcomplete', this._resetUploader);
      this.bind('uploadcancelled', () => {
        this._countMetric('uploadcancelled');
        return this._resetUploader();
      });

      this.bind('uploadsuccess', () => {
        return this._countMetric('uploadsuccess');
      });

      return this.bind('uploadfailed', () => {
        return this._countMetric('uploadfailed');
      });
    }

    _resetUploader() {
      this._maybeReleaseUploadingLock();
      if (this.iframeElem) {
        this._reinitIframeUploader();
      }
      this._processedFile(false);
      if (window.onbeforeunload === this._onBeforeUnload) {
        window.onbeforeunload = null;
      }
      this._uploading = false;
      this._unsetMediaAttrs();
      return this.setProjectId(this.options.projectId);
    }

    _unsetMediaAttrs() {
      this._fileName = null;
      this._fileDescription = null;
      this._projectId = null;
      return (this._embedCodeOptions = null);
    }

    _noUploadSupport() {
      return this.options.noUploadSupport === true;
    }

    _initNoUploadSupport() {
      this.uploadButton?.noUploadSupport(true);
      return this.uploadButton?.showUploadVideo();
    }

    _useIframeUploader() {
      return this.options.iframeUploader === true;
    }

    _initIframeUploader() {
      if (this.buttonElem) {
        this.iframeElem = elemFromObject({
          allowtransparency: 'true',
          frameborder: 0,
          height: '100%',
          scrolling: 'no',
          src: `${proto()}//${Wistia.remote.embedHost()}/embed/iframe_uploader`,
          style: {
            border: 0,
            height: '100%',
            filter: 'Alpha(Opacity=1)',
            msFilter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=1)',
            left: 0,
            opacity: 0.01,
            position: 'absolute',
            top: 0,
            width: '100%',
          },
          tagName: 'iframe',
          width: '100%',
        });
        elemAppend(this.uploadButton.wrapperElem, this.iframeElem);
        this.iframeElem.onload = () => {
          this._sendIframeMessage({
            event: 'handshake',
            accessToken: this._accessToken || this.options.accessToken,
          });
          this.setProjectId(this.options.projectId);

          if (this.options.authParams) {
            this._sendIframeMessage({
              event: 'setAuthParams',
              expires: this.options.authParams.expires,
              generated_at: this.options.authParams.generated_at,
              signature: this.options.authParams.signature,
            });
          }

          if (this.options.createMedia != null) {
            this._sendIframeMessage({
              event: 'setCreateMedia',
              create_media: this.options.createMedia,
            });
          }

          if (this.options.uploadUrl) {
            return this._sendIframeMessage({
              event: 'setUploadUrl',
              url: this.options.uploadUrl,
            });
          }
        };

        return elemRebind(window, 'message', this._handleIframeMessage);
      }
      return this.notice('No button given; uploading will not work.');
    }

    _destroyIframeUploader() {
      elemUnbind(window, 'message', this._handleIframeMessage);
      elemRemove(this.iframeElem);
      return (this.iframeElem = null);
    }

    _reinitIframeUploader() {
      this._destroyIframeUploader();
      return this._initIframeUploader();
    }

    _sendIframeMessage(hash) {
      hash.wistiaUploaderId = this.uuid;
      const result = [];
      for (let k in hash) {
        let v = hash[k];
        result.push(`${encodeURIComponent(k)}=${encodeURIComponent(v)}`);
      }
      return this.iframeElem.contentWindow.postMessage(result.join('&'), '*');
    }

    _handleIframeMessage(event) {
      let parsed;
      if ((parsed = this._parseIframeMessage(event))) {
        if (parsed.event === 'selectedfile') {
          let msg;
          this._currentFile = { name: parsed.fileName };
          let allowFile = true;

          if (
            this.options.allowNonVideoUploads === true &&
            !this._fileHasExtension(this._currentFile)
          ) {
            allowFile = false;
            msg = this._invalidFileExtError(this._currentFile);
            this.error(msg);
            this.uploadButton?.showFailed(msg);
          }

          if (
            this.options.allowNonVideoUploads !== true &&
            !this._fileHasVideoExtension(this._currentFile)
          ) {
            allowFile = false;
            msg = this._invalidVideoExtError(this._currentFile);
            this.error(msg);
            this.uploadButton?.showFailed(msg);
          }

          if (allowFile) {
            return this._processFileMaybeAsync(() => {
              this._validateOptionsBeforeUpload();

              // This is on next tick so postMessage actions called in
              // beforeUpload have a chance to run and modify the iframe
              // contents.
              return doTimeout(`${this.uuid}.start_iframe_upload`, () => {
                return this._sendIframeMessage({ event: 'startupload' });
              });
            });
          }
        } else if (parsed.event === 'uploading') {
          this.trigger('uploadstart', this._currentFile);
          elemStyle(this.iframeElem, { position: 'absolute', left: '-99999em' });
          return this.uploadButton.showUploading('');
        } else if (parsed.event === 'response') {
          const response = JSON.parse(parsed.uploadResponse);
          if (this.options.createMedia === false) {
            return this._onSuccessRaw(response);
          }
          if (response.hashed_id) {
            const params = { project_id: this._projectId, include: 'thumbnail' };

            return this.dataApi.media.find(response.hashed_id, params, {
              success: (response) => {
                return this._onSuccess(response);
              },
              error: () => {
                return this._onError({ error: 'Upload succeeded, but failed to fetch data' });
              },
            });
          }
          return this._onError(response);
        }
      }
    }

    _parseIframeMessage(event) {
      if (typeof event.data === 'string' && /wistiaUploaderId/.test(event.data)) {
        const pairs = event.data.split('&');
        const parsed = {};
        for (let pair of pairs) {
          let [key, val] = Array.from(pair.split('='));
          parsed[decodeURIComponent(key)] = decodeURIComponent(val);
        }

        if (parsed.wistiaUploaderId === this.uuid) {
          return parsed;
        }
        return null;
      }
      return null;
    }

    _initUploadClient() {
      this.upload_client = new UppyUploadAdapter(this._uppyOptions());

      const allowDirectoriesToBeSelected = false;
      const singleFile = true;
      const attributes = {};
      if (this.options.allowNonVideoUploads) {
        if (this.options.mimeTypes) {
          attributes.accept = this.options.mimeTypes;
        }
      } else {
        attributes.accept = 'video/*,video/mp4,video/x-m4v';
      }

      this.upload_client.on('file-added', this.upload_client.onFilesSubmitted);
      this.upload_client.on('upload-progress', this.upload_client.onUppyFileProgress);
      this.upload_client.on('upload-success', this.upload_client.onUppyFileSuccess);
      this.upload_client.on('complete', this._onFileComplete);
      this.upload_client.on('upload-error', this.upload_client.onUploadError);

      if (this.dropZoneElem) {
        this.upload_client.assignDrop(this.dropZoneElem);

        // Text nodes are hidden by default so there's no FOUC due to
        // font-loading. Now that the font is loaded, let's show the text!
        for (let node of this.dropZoneElem.childNodes) {
          if (node.nodeType === 1 && elemHasClass(node, 'wistia_upload_text')) {
            elemStyle(node, { visibility: 'visible' });
          }
        }

        elemBind(this.dropZoneElem, 'dragenter', () => {
          return elemAddClass(this.dropZoneElem, 'wistia_dragover');
        });

        elemBind(this.dropZoneElem, 'dragleave', (event) => {
          if (!elemContainsOffset(this.dropZoneElem, event.pageX, event.pageY)) {
            return elemRemoveClass(this.dropZoneElem, 'wistia_dragover');
          }
        });
      }

      if (
        this.options.dropZoneClickable &&
        this.buttonElem &&
        this.dropZoneElem &&
        elemIsInside(this.buttonElem, this.dropZoneElem)
      ) {
        // If the drop zone is clickable and the button is inside it, just use
        // the drop zone. Otherwise we'll get a double binding on click.
        return this.upload_client.assignBrowse(
          this.dropZoneElem,
          allowDirectoriesToBeSelected,
          singleFile,
          attributes,
        );
      }
      if (this.buttonElem) {
        this.upload_client.assignBrowse(
          this.buttonElem,
          allowDirectoriesToBeSelected,
          singleFile,
          attributes,
        );
      }

      if (this.dropZoneElem && this.options.dropZoneClickable) {
        return this.upload_client.assignBrowse(
          this.dropZoneElem,
          allowDirectoriesToBeSelected,
          singleFile,
          attributes,
        );
      }
    }

    _uppyOptions() {
      return {
        tusPlugin: {
          options: {
            endpoint: `https://${__UPLOAD_V2_URL_ROOT__}`,
            limit: 1,
            headers: {
              Authorization: `Bearer ${this.options.accessToken}`,
            },
          },
        },
        accessToken: this.options.accessToken,
        createMedia: this.options.createMedia,
        wistiaUploader: this,
        requestParams: this._buildAdditionalParams,
        fileFilter: this._onFileAdded.bind(this),
        allowNonVideoUploads: this.options.allowNonVideoUploads,
        beforeUpload: this.options.beforeUpload,
        validateOptionsBeforeUpload: this._validateOptionsBeforeUpload.bind(this),
        useWistiaInAppAuth: this.options.useWistiaInAppAuth,
        host: this.options.host,
      };
    }

    _buildAdditionalParams() {
      const params = {
        project_id: this._projectId,
      };

      // We can accept authentication in the authParams
      // hash, such as a bakery signature or upload token.
      if (this.options.authParams) {
        merge(params, this.options.authParams);
      }

      if (this.options.createMedia === false) {
        params.create_media = 'false';
      }

      if (this.options.channelId) {
        params.channel_id = this.options.channelId;
      }

      if (!this.options.allowNonVideoUploads) {
        params.expected_type = 'video';
      }

      if (this._fileName) {
        params.name = this._fileName;
      }
      if (this._fileDescription) {
        params.description = this._fileDescription;
      }
      return params;
    }

    _validateOptionsBeforeUpload() {
      if (!this.options.demo && this.options.createMedia !== false && !this._projectId) {
        throw new Error(
          'Wistia Uploader: The projectId option must be defined to start an upload.',
        );
      }
    }

    _processFileMaybeAsync(fn) {
      if (!this._processedFile()) {
        const result = this.options.beforeUpload?.(this._currentFile);

        // Duck type return value; if it has a `then` function, treat it
        // like a promise. If the promise succeeds, continue on. This lets
        // the user determine the name/description/project of the media,
        // asynchronously if they want.
        if (typeof result?.then === 'function') {
          const successFn = (response) => this._processedFile(true);
          const errorFn = (error) => {
            this._processedFile.setQueue([]);
            this.error('beforeUpload promise function returned error, cancelling upload', error);
            this.cancel({ flash: false });
            this._onError({
              error: error || "Preprocessing the file before upload didn't finish successfully.",
            });
            return this._allowBrowse();
          };
          result.then(successFn, errorFn);
        } else {
          this._processedFile(true);
        }
      }

      return this._processedFile(fn);
    }

    _onFilesSubmitted(arrayOfFiles, event) {
      this._suppressBrowse();
      return Wistia.Uploader.noneUploading(() => {
        for (let file of arrayOfFiles) {
          if (file._cancelled) {
            return;
          }
        }
        this._uploadingEachFile = true;
        this._addToActivePool();
        return this.upload_client.upload();
      });
    }

    _onFileAdded(file) {
      let msg;
      this._currentFile = file.file;
      if (this.options.useUploadV2) {
        this._currentFile = file;
      }
      // returning false from this method will prevent the file from being added
      // to the queue. When dragging a directory we want to prevent .DS_Store
      // + any other invisible files from being added. Also, firefox, safari and
      // ie don't allow directory uploads at this time and will also return ''
      // for file type if attempted.

      let allowFile = true;
      const gb = 1000000000;

      // invisible file
      if (file.name.charAt(0) === '.') {
        allowFile = false;
      }

      if (!this.upload_client.supportDirectory) {
        if (file.getExtension() === '') {
          allowFile = false;
          msg = 'Uploading directories is not supported by your browser.';
          this._onError({ error: msg });
        }
      }

      if (file.size >= 26 * gb) {
        allowFile = false;
        msg = `${file.name} was not uploaded. The maximum upload size is 26GB.`;
        this._onError({ error: msg });
      }

      if (
        this.options.allowNonVideoUploads !== true &&
        !this._fileHasVideoExtension(this._currentFile)
      ) {
        allowFile = false;
        msg = this._invalidVideoExtError(file);
        this._onError({ error: msg });
      }

      if (allowFile) {
        this.trigger('uploadstart', file);
        this.uploadButton?.showUploading(0);
      } else {
        this._allowBrowse();
      }

      return allowFile;
    }

    _invalidVideoExtError(file) {
      let msg;
      let msgPart1;
      const ext = this._getExtension(file);
      if (ext) {
        msgPart1 = `The file extension ".${ext}" was not recognized as a video.`;
      } else {
        msgPart1 = 'No file extension given.';
      }
      const msgPart2 = `Please verify that you are uploading a video and that is exported with one of these extensions: ${this.videoExtensions().join(
        ', ',
      )}.`;
      return (msg = `${msgPart1} ${msgPart2}`);
    }

    _invalidFileExtError(file) {
      let msg;
      let msgPart1;
      const ext = this._getExtension(file);
      if (ext) {
        msgPart1 = `The file extension ".${ext}" was not recognized.`;
      } else {
        msgPart1 = 'No file extension given.';
      }
      const msgPart2 = `Please verify that you are uploading a file with one of these extensions: ${this.fileExtensions().join(
        ', ',
      )}.`;
      return (msg = `${msgPart1} ${msgPart2}`);
    }

    _getExtension(file) {
      if (file.getExtension) {
        return file.getExtension() || '';
      }
      if (file.name) {
        // iframe uploader files just look like { name: 'thename.mp4' }
        const matches = file.name.match(/[.]([^.]+)$/);
        return (matches && matches[1]) || '';
      }
      return '';
    }

    _fileHasVideoExtension(file) {
      const knownExtRegex = new RegExp(this.videoExtensions().join('|'), 'i');
      const ext = this._getExtension(file);
      if (ext) {
        return knownExtRegex.test(ext);
      }
      return false;
    }

    _fileHasExtension(file) {
      if (this.fileExtensions().length === 0) {
        return true;
      }

      const knownExtRegex = new RegExp(this.fileExtensions().join('|'), 'i');
      const ext = this._getExtension(file);
      if (ext) {
        return knownExtRegex.test(ext);
      }
      return false;
    }

    _onSuccess(response) {
      if (this.options.createMedia !== false) {
        mediaResponseTransformer(response);
      }
      return this._onSuccessRaw(response);
    }

    _onSuccessRaw(response) {
      const media = response.data;
      this.uploadButton?.flashSuccess();
      if (this.options.createMedia !== false && /video/i.test(media.type)) {
        this._showPreview(media);
      }

      if (this.options.createMedia === false) {
        this.trigger('uploadsuccess', this._currentFile, response);
      } else {
        this.trigger('uploadsuccess', this._currentFile, media);
      }

      if (
        this.options.createMedia !== false &&
        /video/i.test(media.type) &&
        this._bindings.uploadembeddable
      ) {
        // always get a video embed code for convenience
        ((file, embedCodeOptions) => {
          return this.getEmbedCode(media, embedCodeOptions, (...args) => {
            return this.trigger('uploadembeddable', file, ...Array.from([media].concat(args)));
          });
        })(this._currentFile, this._embedCodeOptions || this.options.embedCodeOptions);
      }

      return this.trigger('uploadcomplete', this._currentFile);
    }

    _onError(parsed) {
      if (parsed.error) {
        if (parsed.error.message) {
          this.uploadButton?.showFailed(parsed.error.message);
        } else {
          this.uploadButton?.showFailed(parsed.error);
        }
        this.trigger('uploadfailed', this._currentFile, parsed);
        return this.trigger('uploadcomplete', this._currentFile);
      }
      if (parsed.errors) {
        if (parsed.errors instanceof Array) {
          this.uploadButton?.showFailed(parsed.errors.join('\n'));
        } else {
          this.uploadButton?.showFailed(parsed.errors);
        }
        this.trigger('uploadfailed', this._currentFile, parsed);
        return this.trigger('uploadcomplete');
      }
      this.uploadButton?.showFailed();
      this.trigger('uploadfailed', this._currentFile);
      return this.trigger('uploadcomplete', this._currentFile);
    }

    _showPreview(media) {
      let embedHost;
      if (!this.previewElem) {
        return;
      }

      if (this.options.host) {
        embedHost = `embedHost=fast.${this.options.host}`;
      } else {
        embedHost = '';
      }

      this.previewId = seqId('wistia_', `_${media.id}_preview`);
      this.embedCodeElem = elemFromObject({
        id: this.previewId,
        class: `wistia_embed wistia_async_${
          media.id
        } ${this._embedOptionsStringForPreview()} ${embedHost}`,
        style: {
          height: '100%',
          width: '100%',
        },
      });

      this.previewElem.innerHTML = '';
      elemAppend(this.previewElem, this.embedCodeElem);
      elemStyle(this.previewElem, { zIndex: 2 });
      Wistia.embeds.setup();
      this._suppressBrowse();

      // Normally when we replace a video, it will change the height to match
      // the aspect ratio. But in this case, we want to have it match the
      // preview area. This is a weird hack to do that, although it would be
      // nicer to have an embed option that does it for us.
      return Wistia.api(this.previewId, (video) => {
        video.bind('afterreplace', () => {
          video.height(elemHeight(this.previewElem));
          video.width(elemWidth(this.previewElem));
          video.monitor();
          elemStyle(this.previewElem, { width: '100%', height: '100%' });
          video.monitor();
          return video.unbind;
        });
        return video.embedded(() => {
          if (this.dropZoneElem) {
            elemAddClass(this.dropZoneElem, 'wistia_preview_visible');
          }
          if (this.buttonElem) {
            elemAddClass(this.buttonElem, 'wistia_preview_visible');
          }
          if (this.previewElem) {
            return elemAddClass(this.previewElem, 'wistia_preview_visible');
          }
        });
      });
    }

    _embedOptionsStringForPreview() {
      if (this.options.embedCodeOptions) {
        return objectToQueryParams(this.options.embedCodeOptions).replace(/&/, ' ');
      }
      return '';
    }

    _removePreview() {
      if (!this.previewElem || !this.previewId) {
        return;
      }

      if (this.dropZoneElem) {
        elemRemoveClass(this.dropZoneElem, 'wistia_preview_visible');
      }
      if (this.buttonElem) {
        elemRemoveClass(this.buttonElem, 'wistia_preview_visible');
      }
      if (this.previewElem) {
        elemRemoveClass(this.previewElem, 'wistia_preview_visible');
      }

      Wistia.api(this.previewId)?.remove();
      this.previewId = null;
      this._allowBrowse();
      return elemStyle(this.previewElem, { zIndex: '' });
    }

    _suppressBrowse() {
      this.buttonElem?.setAttribute('data-suppress-browse', 'true');
      return this.dropZoneElem?.setAttribute('data-suppress-browse', 'true');
    }

    _allowBrowse() {
      this.buttonElem?.removeAttribute('data-suppress-browse');
      return this.dropZoneElem?.removeAttribute('data-suppress-browse');
    }

    _maybeReleaseUploadingLock() {
      if (
        this._uploadingEachFile &&
        (this.upload_client.files.length === 0 || this.upload_client.progress() >= 1)
      ) {
        this._uploadingEachFile = false;
        this._removeFromActivePool();
      }
      return Wistia.Uploader.trigger('uploadingchange');
    }

    _onFileComplete() {
      this.info('Upload complete', ...arguments);
      this._currentFileParams = null;
      return this.upload_client.cancelAll();
    }

    _onBeforeUnload() {
      if (this.options.onBeforeUnload === false) {
        return this.notice('onBeforeUnload set to false, allowing unload with no warning.');
      }
      if (this.options.onBeforeUnload) {
        if (typeof this.options.onBeforeUnload === 'string') {
          return this.options.onBeforeUnload;
        }
        return this.options.onBeforeUnload.apply(window, arguments);
      }
      return 'Are you sure you wish to leave the page? Any active uploads will be lost.';
    }

    _onClickedCancel() {
      return this.cancel();
    }

    _onUploadButtonStateChange(state) {
      if (state === 'upload_video' && !this.previewId) {
        return this._allowBrowse();
      }
      return this._suppressBrowse();
    }

    _defaultMetricData() {
      const connectionType = (
        window.navigator.connection ||
        window.navigator.mozConnection ||
        window.navigator.webkitConnection
      )?.type;

      const safeOptions = clone(this.options);
      if (safeOptions.accessToken) {
        safeOptions.accessToken = '<redacted>';
      } else {
        safeOptions.accessToken = '<missing>';
      }
      eachLeaf(safeOptions, (leafVal, path, parent, key) => {
        if (!isBasicType(leafVal)) {
          return (parent[key] = 'unstringifyible');
        }
      });

      for (let k in safeOptions) {
        let v = safeOptions[k];
        if (typeof v === 'function') {
          safeOptions[k] = '<function>';
        }
      }

      const fileInfo = {};
      if (this._currentFile) {
        fileInfo.name = this._currentFile.name;
        if (this._currentFile.size != null) {
          fileInfo.size = this._currentFile.size;
        }
        if (this._currentFile.type != null) {
          fileInfo.type = this._currentFile.type;
        }
      }

      const uploadParams = {};
      if (this._projectId) {
        uploadParams.projectId = this._projectId;
      }
      if (this._fileName) {
        uploadParams.fileName = this._fileName;
      }
      if (this._fileDescription) {
        uploadParams.fileDescription = this._fileDescription;
      }
      if (this._embedCodeOptions) {
        uploadParams.embedCodeOptions = this._embedCodeOptions;
      }
      if (this._accessToken) {
        uploadParams.accessToken = 'set-via-setAccessToken';
      }

      return {
        locationHref: location.href,
        location:
          location?.protocol?.length && location?.hostname?.length
            ? `${location.protocol}//${location.hostname}`
            : null,
        agent: navigator.userAgent,
        connection_type: connectionType,
        options: safeOptions,
        file: fileInfo,
        uploadParams,
      };
    }

    _countMetric(key, val, extraData) {
      extraData = merge(this._defaultMetricData(), extraData);
      return Wistia.Metrics.count(`uploader/${key}`, 1, extraData);
    }

    static noneUploading(callback) {
      let u;
      let uploadingUploaders = (() => {
        const result = [];
        for (u of this.activePool) {
          if (u._uploadingEachFile) {
            result.push(u);
          }
        }
        return result;
      })();
      let noneUploading = uploadingUploaders.length === 0;

      if (noneUploading) {
        callback();
        return true;
      }
      Wistia.Uploader.bind('uploadingchange', () => {
        uploadingUploaders = (() => {
          const result1 = [];
          for (u of this.activePool) {
            if (u._uploadingEachFile) {
              result1.push(u);
            }
          }
          return result1;
        })();
        noneUploading = uploadingUploaders.length === 0;
        if (noneUploading) {
          callback();
          return Wistia.Uploader.unbind;
        }
        return null;
      });
      return false;
    }
  };

  Wistia.Uploader.activePool = [];

  Wistia.mixin(Wistia.Uploader.prototype, Wistia.bindable);
  Wistia.mixin(Wistia.Uploader, Wistia.bindable);
  return Wistia.mixin(Wistia.Uploader.prototype, Wistia.logHelpers);
});
