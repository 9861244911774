/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */

import { createUrl, parseUrl } from 'utilities/url.js';
import { isObject, merge } from 'utilities/obj.js';

window._wq.push((Wistia) => {
  if (Wistia.DataApi) {
    return;
  }

  Wistia.DataApi = class DataApi {
    constructor(options) {
      this.options = options;
      this.accessToken = this.options.accessToken;
      this.media = new Wistia.DataApi.Media(this);
    }

    request(type, path, params = {}, options = {}) {
      let url;
      if (type === 'GET' || type === 'HEAD') {
        // GET and HEAD don't put params in the request body
        url = this.expandUrl(path, params);
      } else {
        // POST, PUT, DELETE, etc. put params in the request body.
        url = this.expandUrl(path, {});
        if (!options.data || isObject(options.data)) {
          options = merge({ data: params }, options);
        }
      }

      // We POST data in JSON and receive responses in JSON.
      const extendedOptions = merge(
        {
          method: type,
          dataType: 'json',
          contentType: 'application/json',
          headers: { Authorization: `Bearer ${this.accessToken}` },
        },
        options,
      );

      if (extendedOptions.data && isObject(extendedOptions.data)) {
        extendedOptions.data = JSON.stringify(extendedOptions.data);
      }

      extendedOptions.fallbackToJsonp = true;

      this.info('request', url, extendedOptions);

      return fetch(url, extendedOptions);
    }

    get() {
      return this.request('GET', ...arguments);
    }

    post() {
      return this.request('POST', ...arguments);
    }

    put(path, params, options) {
      params = merge({ _method: 'put' }, params);
      return this.request('POST', params, options);
    }

    delete(path, params, options) {
      params = merge({ _method: 'delete' }, params);
      return this.request('POST', params, options);
    }

    expandUrl(path, params = {}) {
      let parsed = parseUrl(parsed);

      const host = `api.${__HOSTNAME__}`;

      const url = createUrl({
        protocol: 'https:',
        host,
        path,
        params,
      });

      return url.absolute();
    }
  };

  return Wistia.mixin(Wistia.DataApi.prototype, Wistia.logHelpers);
});
