/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
// Define the _wapi queue after Wistia.DataApi is defined. Anything that had been
// queued in window._wapi will then be drained!

window._wq = window._wq || [];
window._wq.push((Wistia) => Wistia.addQueue('_wapiq'));

const proto = /^http:\/\//.test(location.href) ? 'http:' : 'https:';

// If E-v1 hasn't been loaded yet, let's do that.
if (!window.Wistia?.wistia) {
  const s = document.createElement('script');
  s.type = 'text/javascript';
  s.async = true;
  s.src = `${proto}//${__FAST_HOSTNAME__}/assets/external/E-v1.js`;
  (document.body || document.head).appendChild(s);
}
