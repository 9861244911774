import Uppy from '@uppy/core';
import Tus from '@uppy/tus';
import { assign } from 'utilities/obj.js';
import { mediaResponseTransformer } from './_medias_controller_api_response_transformer.js';
import { appHostname } from '../../appHostname.js';

export class UppyUploadAdapter {
  constructor(params) {
    this.unbinds = [];
    this.allowNonVideoUploads = params.allowNonVideoUploads;
    this.mimeTypes = params.mimeTypes || this.allowedMimeTypes();
    this.wistiaUploader = params.wistiaUploader;
    this.supportDirectory = /Chrome/.test(window.navigator.userAgent);
    this.requestParams = params.requestParams;
    this.fileFilter = params.fileFilter;
    this.accessToken = params.accessToken;
    this.createMedia = params.createMedia;
    this.beforeUpload = params.beforeUpload;
    this.validateOptionsBeforeUpload = params.validateOptionsBeforeUpload;
    this.useWistiaInAppAuth = params.useWistiaInAppAuth;
    this.host = params.host;
    this.uppy = new Uppy({
      restrictions: this.restrictions(),
      autoProceed: false,
      onBeforeFileAdded: (file, _files) => {
        return this.fileFilter(this.createUploaderReadableFile(file));
      },
    }).use(Tus, params.tusPlugin.options);
    this.uppy.on('upload-started', this.onUploadStart.bind(this));
  }

  onUploadStart(file) {
    if (!this.uppy.getFile(file.id)) {
      this.wistiaUploader._countMetric('uppy-upload-start-failure');
    }
  }

  allowedMimeTypes() {
    return ['video/*', 'video/mp4', 'video/x-m4v'];
  }

  restrictions() {
    const defaultRestrictions = { allowedFileTypes: this.mimeTypes };
    if (this.allowNonVideoUploads) {
      return {};
    }
    return defaultRestrictions;
  }

  get files() {
    return this.uppy.getFiles();
  }

  progress() {
    return this.uppy.getState().totalProgress;
  }

  addFile(file) {
    const convertedFile = this.createUppyfile(file);
    try {
      this.uppy.addFile(convertedFile);
    } catch (e) {
      console.error(e);
    }
  }

  createUppyfile(file) {
    const attributes = this.requestParams();
    const originalName = file.name;
    const uppyFile = {
      data: file,
      name: attributes.name || file.name,
      type: file.type,
      meta: attributes,
    };
    uppyFile.meta.originalName = originalName;
    return uppyFile;
  }

  createUploaderReadableFile(file) {
    function getExtension(fromFile) {
      return function () {
        return fromFile.meta.originalName
          .substr((~-fromFile.meta.originalName.lastIndexOf('.') >>> 0) + 2)
          .toLowerCase();
      };
    }

    const uploaderReadableFile = {
      file,
      name: file.name,
      size: file.size,
      getExtension: getExtension(file),
    };
    return uploaderReadableFile;
  }

  on(uppyEvent, fn) {
    this.uppy.on(uppyEvent, fn.bind(this));
  }

  emit(event, ...args) {
    this.uppy.emit(event, ...args);
  }

  onFilesSubmitted(file) {
    this.wistiaUploader._onFilesSubmitted([file]);
  }

  cancelAll() {
    this.uppy.cancelAll();
  }

  onUppyFileProgress(file, uploaderInfoObject) {
    const ratio = uploaderInfoObject.bytesUploaded / uploaderInfoObject.bytesTotal;
    this.wistiaUploader.trigger('uploadprogress', file, ratio);
  }

  onUploadError(file, errorMesage) {
    const errorObjectForTheUploader = {
      error: {
        message: errorMesage,
      },
    };
    this.wistiaUploader._onError(errorObjectForTheUploader);
  }

  onUppyFileSuccess(file, response) {
    if (this.createMedia !== false) {
      const fileId = this.extractFileId(response.uploadURL);

      let customUrl = this.mediaInfoAndCreationEndpoint(fileId);
      fetch(customUrl, {
        contentType: 'application/x-www-form-urlencoded',
        method: 'POST',
        dataType: 'json',
        headers: {
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((resp) => resp.json())
        .then((successResponse) => {
          this.transformToCreateMediaResponse(successResponse);
          successResponse.data.originalUrl = `${this.generateExtensionlessOriginalUrl(fileId)}.bin`;
          this.wistiaUploader._onSuccessRaw(successResponse);
        })
        .catch((errorResponse) => {
          this.wistiaUploader?._onError(errorResponse);
        });
    } else {
      this.transformToDoNotCreateMediaResponse(file, response);
      this.wistiaUploader._onSuccessRaw(response);
    }
  }

  transformToCreateMediaResponse(response) {
    mediaResponseTransformer(response);
  }

  transformToDoNotCreateMediaResponse(file, response) {
    const fileId = this.extractFileId(response.uploadURL);

    response.extension = file.extension;
    response.filesize = file.size;
    response.hashed_id = fileId;
    response.mediaUrl = `${this.generateExtensionlessOriginalUrl(fileId)}.${file.extension}`;
    response.name = file.name;
  }

  extractFileId(url) {
    return url.split('+')[0].split('/').pop();
  }

  generateExtensionlessOriginalUrl(fileId) {
    return `https://${__SSL_EMBED_HOST__}/deliveries/${fileId}`;
  }

  mediaInfoAndCreationEndpoint(fileId) {
    const host = this.host ? `api.${this.host}` : appHostname('api');
    let url = `https://${host}/v2/uploaded_medias?media[upload_id]=${fileId}`;
    url += '&include=thumbnail';
    url += '&embeddable_uploader=true';
    if (this.createMedia !== false) {
      url += '&create_embed=true';
    }
    url += this.userDefinedRequestParams();
    return url;
  }

  userDefinedRequestParams() {
    const requestParams = this.requestParams();
    let extraParams = '';
    if (requestParams.project_id) {
      extraParams += `&project_id=${requestParams.project_id}`;
    }
    if (requestParams.description) {
      extraParams += `&media[description]=${encodeURIComponent(requestParams.description)}`;
    }
    if (requestParams.name) {
      extraParams += `&media[name]=${encodeURIComponent(requestParams.name)}`;
    }
    return extraParams;
  }

  cancel() {
    const currentFile = this.uppy.getFiles()[0];
    if (currentFile) {
      this.uppy.removeFile(currentFile.id);
    }
  }

  addUnbindableEventListener(domNode, type, callback, options) {
    domNode.addEventListener(type, callback, options);
    this.unbinds.push([domNode, type, callback, options]);
  }

  unbindEventListeners() {
    this.unbinds.forEach(([domNode, type, callback, options]) => {
      domNode.removeEventListener(type, callback, options);
    });
  }

  destroy() {
    this.unbindEventListeners();
  }

  assignBrowse(domNode, _isDirectory, _singleFile, attributes) {
    let input;
    if (domNode.tagName === 'INPUT' && domNode.type === 'file') {
      input = domNode;
    } else {
      input = document.createElement('input');
      input.setAttribute('type', 'file');

      assign(input.style, {
        visibility: 'hidden',
        position: 'absolute',
        width: '1px',
        height: '1px',
      });

      domNode.appendChild(input);

      this.addUnbindableEventListener(
        domNode,
        'click',
        (e) => {
          if (domNode.tagName === 'A') {
            e.stopPropagation();
            e.preventDefault();
          }

          // SCHNEE CHANGE: Allow us to suppress browse so that we can put
          // things inside clickable areas occasionally.
          if (!domNode.getAttribute('data-suppress-browse')) {
            input.click();
          }
        },
        false,
      );

      // Stop the click event on the child from triggering the domNode's callback
      this.addUnbindableEventListener(
        input,
        'click',
        (e) => {
          e.stopPropagation();
        },
        true,
      );
    }

    this.addUnbindableEventListener(
      input,
      'change',
      (e) => {
        if (e.target && e.target.files && e.target.files.length > 0) {
          this.addFile(e.target.files[0]);
          e.target.value = '';
        }
      },
      false,
    );

    for (let key in attributes) {
      if (Object.hasOwn(attributes, key)) {
        input.setAttribute(key, attributes[key]);
      }
    }

    return this;
  }

  assignDrop(domNodes) {
    if (typeof domNodes.length === 'undefined') {
      domNodes = [domNodes];
    }
    domNodes.forEach((domNode) => {
      this.addUnbindableEventListener(domNode, 'dragover', this.preventEvent, false);
      this.addUnbindableEventListener(domNode, 'dragenter', this.preventEvent, false);
      this.addUnbindableEventListener(domNode, 'drop', this.onDrop.bind(this), false);
    }, this);
  }

  preventEvent(event) {
    event.preventDefault();
  }

  onDrop(event) {
    const _this = this;
    event.preventDefault();
    const { dataTransfer } = event;
    if (dataTransfer.items && dataTransfer.items[0] && dataTransfer.items[0].webkitGetAsEntry) {
      webkitReadDataTransfer(event);
    } else {
      for (let i = 0; i < dataTransfer.files.length; i++) {
        this.addFile(dataTransfer.files[0]);
      }
    }

    function webkitReadDataTransfer(event) {
      let queue = event.dataTransfer.items.length;
      const files = [];
      const items = Array.from(event.dataTransfer.items);
      items.forEach((item) => {
        const entry = item.webkitGetAsEntry();
        if (!entry) {
          decrement();
          return;
        }
        if (entry.isFile) {
          // due to a bug in Chrome's File System API impl - #149735
          fileReadSuccess(item.getAsFile(), entry.fullPath);
        } else {
          readDirectory(entry.createReader());
        }
      });

      function readDirectory(reader) {
        reader.readEntries((entries) => {
          if (entries.length) {
            queue += entries.length;
            entries.forEach((entry) => {
              if (entry.isFile) {
                const fullPath = entry.fullPath;
                entry.file((file) => {
                  fileReadSuccess(file, fullPath);
                }, readError);
              } else if (entry.isDirectory) {
                readDirectory(entry.createReader());
              }
            });
            readDirectory(reader);
          } else {
            decrement();
          }
        }, readError);
      }

      function fileReadSuccess(file, fullPath) {
        // relative path should not start with "/"
        file.relativePath = fullPath.substring(1);
        files.push(file);
        decrement();
      }

      function readError(fileError) {
        throw fileError;
      }

      function decrement() {
        if ((queue -= 1) === 0) {
          files.forEach((file) => {
            _this.addFile(file);
          });
        }
      }
    }
  }

  upload() {
    const nextStepAfterInAppAuth = () => {
      if (this.beforeUpload) {
        return this.handleBeforeUpload.bind(this);
      }
      return this.validateThenUpload.bind(this);
    };

    if (this.useWistiaInAppAuth) {
      this.setAuthenticationToken().then(nextStepAfterInAppAuth());
    } else if (this.beforeUpload) {
      this.handleBeforeUpload();
    } else {
      this.validateThenUpload();
    }
  }

  validateThenUpload() {
    this.validateOptionsBeforeUpload();

    function ensureProjectIdSetEvenAfterUploadHandler() {
      this.uppy.getFiles().forEach((file) => {
        if (
          this.requestParams().project_id === undefined ||
          this.requestParams().project_id === 'undefined'
        ) {
          delete file.meta.project_id;
        } else {
          file.meta.project_id = this.requestParams().project_id;
        }
      });
    }

    ensureProjectIdSetEvenAfterUploadHandler.call(this);

    try {
      this.uppy.upload();
    } catch (e) {
      this.wistiaUploader._countMetric('uppy-fail', null, {
        uploadError: e.toString(),
      });
      throw e;
    }
  }

  handleBeforeUpload() {
    const file = this.uppy.getFiles()[0].data;
    const errorFunction = (error) => {
      this.wistiaUploader.error(
        'beforeUpload promise function returned error, cancelling upload',
        error,
      );
      this.wistiaUploader.cancel({
        flash: false,
      });
      this.wistiaUploader._onError({
        error: error || "Preprocessing the file before upload didn't finish successfully.",
      });
      this.wistiaUploader._allowBrowse();
    };
    const beforeUploadResult = this.beforeUpload(file);

    if (beforeUploadResult && typeof beforeUploadResult.then === 'function') {
      beforeUploadResult.then(this.validateThenUpload.bind(this), errorFunction);
    } else {
      this.validateThenUpload();
    }
  }

  setAuthenticationToken() {
    const state = this.uppy.getState();
    const authHasBeenSet = state.plugins.tokenInitialized === undefined;
    const uploadWithContext = !!window.WistiaContext;

    const getUploadAuthParam = (responseData) => {
      if (uploadWithContext) {
        const uploadAuth = window.WistiaContext.granary.creds.upload_auth_param;
        if (uploadAuth) {
          return uploadAuth;
        }
      }
      const token = responseData.token;
      this.accessToken = token;
      return `Bearer ${token}`;
    };
    const setMediaCreateTokenOnWistiaContext = (responseData) => {
      window.WistiaContext.granary.creds.auth_token = responseData.token;
    };
    const setHeaderAndUpload = (responseData) => {
      this.setTusHeaderToken(getUploadAuthParam(responseData));
      this.setPluginInitializationState(true);
    };

    if (authHasBeenSet) {
      this.setPluginInitializationState(false);
      return new Promise((resolve) => {
        const token_name = encodeURIComponent(window._auth_token_name);
        const token = encodeURIComponent(window._auth_token);
        fetch(`/expiring_access_token?${token_name}=${token}`, {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
          },
        })
          .then((resp) => resp.json())
          .then((response) => {
            if (uploadWithContext) {
              setMediaCreateTokenOnWistiaContext(response);
            }
            setHeaderAndUpload(response);
            resolve();
          });
      });
    }
    return new Promise((resolve) => {
      resolve();
    });
  }

  setPluginInitializationState(value) {
    this.uppy.setState({
      plugins: {
        tokenInitialized: value,
      },
    });
  }

  setTusHeaderToken(headerValue) {
    this.uppy.getPlugin('Tus').opts.headers.Authorization = headerValue;
  }

  getStateForTesting() {
    return this.uppy.getState();
  }

  setStateForTesting(state) {
    this.uppy.setState(state);
  }

  getUppy() {
    return this.uppy;
  }
}
